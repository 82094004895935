import React from 'react'
import { useMyContext } from '../../../../Context/MyContextProvider'

const StaticMissedCall = () => {
    const { systemSetting } = useMyContext();

    if (!systemSetting?.missed_call_no) return null; 

    const formatPhoneNumber = (number) => {
        let cleanNumber = number?.replace(/\D/g, ''); 

        if (cleanNumber?.length > 10) {
            return `+${cleanNumber?.slice(0, -10)}-${cleanNumber?.slice(-10, -6)}-${cleanNumber?.slice(-6, -2)}-${cleanNumber?.slice(-2)}`;
        } else {
            return `${cleanNumber?.slice(0, 4)}-${cleanNumber?.slice(4, 8)}-${cleanNumber?.slice(8)}`;
        }
    };

    const formattedNumber = formatPhoneNumber(systemSetting?.missed_call_no);

    return (
        <a
            className="btn btn-primary btn-landing position-fixed"
            style={{ right: '-115px', top: '30rem', zIndex: 9999 }}
            href={`tel:${systemSetting.missed_call_no}`}
        >
            Give a missed call: {formattedNumber}
        </a>
    );
};

export default StaticMissedCall;

