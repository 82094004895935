import React, { memo, Fragment, useRef, useState, useEffect } from "react";
import $ from "jquery";
import useDataTable from "../../../../components/hooks/useDatatable";
import axios from "axios";
import Swal from "sweetalert2";
import { useMyContext } from "../../../../Context/MyContextProvider";
import * as XLSX from "xlsx";
import POSPrintModal from "./POSPrintModal";
import CommonListing from "../CustomUtils/CommonListing";
const PosBooking = memo(() => {
    const { api, UserData, formatDateTime, authToken, formatDateRange, UserPermissions } = useMyContext();
    const [bookings, setBookings] = useState([])
    const [bookingData, setBookingData] = useState([])
    const [dateRange, setDateRange] = useState('');
    const GetBookings = async () => {
        setLoading(true);
        const queryParams = dateRange ? `?date=${dateRange}` : '';
        const url = `${api}pos-bookings/${UserData?.id}${queryParams}`;
        await axios.get(url, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((res) => {
                if (res.data.status) {
                    setBookings(res.data.bookings)
                }
            }).catch((err) =>
                console.log(err)
            ).finally(setLoading(false))
    }

    useEffect(() => {
        GetBookings()
    }, [])
    useEffect(() => {
        GetBookings()
    }, [dateRange])


    const listtableRef = useRef(null);
    const columns = useRef([
        {
            data: null, // No direct data mapping
            orderable: false,
            title: "#",
            render: (data, type, row, meta) => meta.row + 1 // Use index + 1 as OrderId
        },
        { data: "ticket.event.name", title: "Event" },
        {
            data: "ticket.event.date_range",
            title: "Event Dates",
            render: function (data) {
                return formatDateRange(data);
            },
        },
        { data: "user_name", title: "POS User" },
        { data: "reporting_user_name", title: "Organizer" },

        { data: "ticket.name", title: "Ticket" },
        { data: "quantity", title: "Quantity" },
        {
            data: "discount",
            title: "Discount",
            render: function (data) {
                return `<p class="text-danger">${data}</p>`;
            },
        },
        { data: "amount", title: "Amount" },
        {
            data: "status",
            title: "Status",
            render: function (data) {
                return `<span class="badge p-1 bg-${data === "0" ? 'warning' : 'success'}">${data === "0" ? 'Uncheck' : 'Checked'}</span>`;
            },
        },
        {
            data: null,
            orderable: false,
            searchable: false,
            title: "Action",
            render: function (data) {
                const isDisabled = (data?.is_deleted === true || data?.status === "1") ? 'disabled' : '';
                return `<div class="flex align-items-center list-user-action">
                            <button class="btn btn-sm btn-icon btn-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Print" data-id=${data?.id} data-method="Print" data-table="action" ${isDisabled}>
                                <i class="fa-solid fa-print"></i>
                            </button>
                        </div>`;
            },
        },
        {
            data: "created_at",
            title: "Purchase Date",
            render: function (data) {
                return formatDateTime(data);
            },
        },
        {
            data: null,
            orderable: false,
            searchable: false,

            title: "Disable",
            render: function (data) {
                const Checked = data?.is_deleted === true && "checked";
                return `<div class="flex align-items-center list-user-action">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" data-table="action" data-id=${data?.id} data-method="disable" ${Checked}>
                            </div>
                        </div>`;
            },
        },
        { data: "name", title: "Customer" },
        { data: "number", title: "Contact" },
    ]);
    useDataTable({
        tableRef: listtableRef,
        columns: columns.current,
        data: bookings,
        actionCallback: (data) => {
            switch (data.method) {
                case 'Print':
                    fetchBooking(data?.id);
                    break;
                case 'edit':
                    console.log("hello");
                    break;
                case 'disable':
                    DeleteBooking(data?.id);
                    break;
                default:
                    break;
            }
        }
    });

    if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
    }
    $("#datatable-ecom").DataTable({
        createdRow: function (row, data, dataIndex) {
            $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
    });

    //   the function 
    const fetchBooking = (id) => {
        let data = bookings?.find((item) => item?.id === id)
        setBookingData(data)
        setShowPrintModel(true)
    }

    const DeleteBooking = async (id) => {
        let data = bookings?.find((item) => item?.id === id)
        if (data?.is_deleted === true) {
            await axios.get(`${api}restore-pos-booking/${id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            })
                .then((res) => {
                    if (res.data.status) {
                        GetBookings()
                        Swal.fire({
                            icon: "success",
                            title: "Ticket Enabled!",
                            text: "Ticket enabled succesfully.",
                        });
                    }
                }).catch((err) =>
                    console.log(err)
                )
        } else {
            await axios.delete(`${api}delete-pos-booking/${id}`)
                .then((res) => {
                    if (res.data.status) {
                        GetBookings()
                        Swal.fire({
                            icon: "success",
                            title: "Ticket Disabled!",
                            text: "Ticket disabled succesfully.",
                        });
                    }
                }).catch((err) =>
                    console.log(err)
                )
        }
    }

    const [showPrintModel, setShowPrintModel] = useState(false);
    const closePrintModel = () => {
        setShowPrintModel(false)
    }

    const HandleExportAgentReport = () => {
        // Prepare the data for export
        const exportData = bookings?.map((booking, index) => ({
            "#": index + 1,
            "Event": booking.ticket.event.name,
            "Event Dates": formatDateRange(booking.ticket.event.date_range),
            "POS User": booking.user_name,
            "Organizer": booking.reporting_user_name,
            "Ticket": booking.ticket.name,
            "Quantity": booking.quantity,
            "Discount": booking.discount,
            "Amount": booking.amount,
            "Status": booking.status === "0" ? "Uncheck" : "Checked",
            "Purchase Date": formatDateTime(booking.created_at),
            "Customer": booking.name,
            "Contact": booking.number,
        }));

        // Create a worksheet
        const worksheet = XLSX.utils.json_to_sheet(exportData);

        // Create a workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "POS Bookings");

        // Export the file
        XLSX.writeFile(workbook, "POS_Bookings.xlsx");
    };
    const [loading, setLoading] = useState(true);
    return (
        <>
            <POSPrintModal
                showPrintModel={showPrintModel}
                closePrintModel={closePrintModel}
                event={bookingData?.ticket?.event}
                bookingData={bookingData}
                subtotal={bookingData?.ticket?.price * bookingData?.quantity}
                totalTax={Math.max(0, ((bookingData?.amount - -bookingData?.discount) - (bookingData?.ticket?.price * bookingData?.quantity))).toFixed(2)}
                discount={bookingData?.discount}
                grandTotal={bookingData?.amount}
            />
            <CommonListing
                tile={'POS'}
                UserPermissions={UserPermissions}
                bookings={bookings}
                dateRange={dateRange}
                loading={loading}
                listtableRef={listtableRef}
                columns={columns}
                setDateRange={setDateRange}
                HandleExportAgentReport={HandleExportAgentReport}
                bookingLink={'/dashboard/pos'}
            />
        </>
    );
});

PosBooking.displayName = "PosBooking";
export default PosBooking;
