import React from 'react'
import { Card, Col, Table } from 'react-bootstrap'
import { useMyContext } from '../../../../Context/MyContextProvider';

// export const SystemVars = [
//     { key: ":C_number", value: "Customer Number" },
//     { key: ":O_number", value: "Organizer Number" },
//     { key: ":A_number", value: "Agent Number" },
//     { key: ":C_Name", value: "Customer Name" },
//     { key: ":O_Name", value: "Organizer Name" },
//     { key: ":A_Name", value: "Agent Name" },
//     { key: ":Event_Name", value: "Event Name" },
//     { key: ":Event_Location", value: "Event Location" },
//     { key: ":Event_DateTime", value: "Event DateTime" },
//     { key: ":Ticket_Name", value: "Ticket Name/Category" },
//     { key: ":Seat_Number", value: "Seat Number (If seating chart available)" },
//     { key: ":T_QTY", value: "Ticket Quantity" },
//     { key: ":S_Link", value: "Short Link" },
// ];
const SytemVariables = () => {
    const {SystemVars } = useMyContext();

    return (
        <Col lg="12">
            <Card>
                <Card.Header>
                    <h4 className="card-title">System Variables</h4>
                </Card.Header>
                <Card.Body className="table-fixed p-0">
                    <div className="table-responsive mt-4">
                        <Table responsive striped className="mb-0" role="grid">
                            <thead>
                                <tr className="bg-white">
                                    <th scope="col">Key</th>
                                    <th scope="col">Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {SystemVars?.map((item, index) => (
                                    <tr key={index}>
                                        <td className={index % 2 === 0 ? "" : "text-dark"}>{item?.key}</td>
                                        <td className={index % 2 === 0 ? "" : "text-dark"}>{item?.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default SytemVariables
