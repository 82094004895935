import React from 'react'
import { Nav } from 'react-bootstrap';

const DetailsTab = () => {
    const tabs = [
        { key: "layout", eventKey: "fourth", target: "#nav-layout", label: "Event Layout", ariaSelected: "true" },
        { key: "description", eventKey: "first", target: "#nav-description", label: "Description", ariaSelected: "false" },
        { key: "info", eventKey: "second", target: "#nav-info", label: "Terms & Condition", ariaSelected: "false" },
        { key: "media", eventKey: "third", target: "#nav-review", label: "Media", ariaSelected: "false" },
        { key: "location", eventKey: "fifth", target: "#nav-location", label: "Location Map", ariaSelected: "false" }
    ];
    return (
        <div className="mb-0 nav nav-tabs" id="nav-tab1" role="tablist">
            {tabs.map(({ key, eventKey, target, label, ariaSelected }) => (
                <Nav.Link
                    key={key}
                    eventKey={eventKey}
                    id={`nav-${key}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={target}
                    type="button"
                    role="tab"
                    aria-controls={target.substring(1)}
                    aria-selected={ariaSelected}
                >
                    {label}
                </Nav.Link>
            ))}
        </div>
    )
}

export default DetailsTab
