import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import useDataTable from '../../../../components/hooks/useDatatable';
import $ from "jquery";
import { useMyContext } from '../../../../Context/MyContextProvider';
import axios from 'axios';
import CommonDateRange from '../CustomHooks/CommonDateRange';

const EventReports = () => {
    const { api, UserData, authToken } = useMyContext();
    const [report, setReport] = useState();
    const [dateRange, setDateRange] = useState('');
    const [type, setType] = useState('active');
    const listtableRef = useRef(null);

    const GetBookings = async () => {
        const queryParams = [];
        if (dateRange) queryParams.push(`date=${dateRange}`);
        if (type) queryParams.push(`type=${type}`);
        const url = `${api}event-reports/${UserData?.id}${queryParams.length ? `?${queryParams?.join('&')}` : ''}`;
        await axios.get(url, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((res) => {
                setReport(res.data.data);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        GetBookings();
    }, []);
    useEffect(() => {
        GetBookings();
    }, [dateRange, type]);

    const columns = useRef([
        {
            data: null,
            orderable: false,
            title: "#",
            render: (data, type, row, meta) => meta.row + 1
        },
        {
            data: null,
            title: "Event",
            render: function (row) {
                return `<p title=${row?.event_name}>${row?.event_name}</p>`;
            },
        },
        {
            data: null,
            title: "Organizer",
            render: function (row) {
                return row?.organizer;
            },
        },
        {
            data: null,
            title: "Avail Ticket",
            render: function (row) {
                return row?.ticket_quantity;
            },
        },
        {
            data: null,
            title: "Online",
            render: function (row) {
                return row?.non_agent_bookings;
            },
        },
        {
            data: null,
            title: "Agent",
            render: function (row) {
                return row?.agent_bookings;
            },
        },
        {
            data: null,
            title: "POS",
            render: function (row) {
                return (
                    row?.pos_bookings_quantity
                );
            },
        },
        {
            data: null,
            title: "Total T",
            render: function (row) {
                return (row?.non_agent_bookings) + (row?.agent_bookings) + (row?.pos_bookings_quantity);
            },
        },
        {
            data: null,
            title: "Chek-ins",
            render: function (row) {
                return row?.total_ins;
            },
        },
        {
            data: null,
            title: "Online Sale",
            render: function (row) {
                return '₹' + (row?.online_base_amount).toFixed(2);
            },
        },
        {
            data: null,
            title: "Agent Sale",
            render: function (row) {
                return '₹' + (row?.agent_base_amount).toFixed(2);
            },
        },
        {
            data: null,
            title: "POS Sale",
            render: function (row) {
                return '₹' + (row?.pos_base_amount).toFixed(2);
            },
        },
        // {
        //     data: null,
        //     title: "Agent + Pos",
        //     render: function (row) {
        //         return '₹' + (row?.agent_base_amount + row?.pos_base_amount).toFixed(2);
        //     },
        // },
        {
            data: null,
            title: "Disc",
            render: function (row) {
                return '₹' + ((row?.online_discount) + (row?.pos_discount) + (row?.agent_discount)).toFixed(2);
            },
        },
        {
            data: null,
            title: "Con Fees",
            render: function (row) {
                return '₹' + ((row?.online_convenience_fee) + (row.pos_convenience_fee)).toFixed(2);
            },
        },
    ]);
    useDataTable({
        tableRef: listtableRef,
        columns: columns.current,
        data: report
    });

    if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
    }
    $("#datatable-ecom").DataTable({
        createdRow: function (row, data, dataIndex) {
            $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
    });
    return (
        <Row>
            <Col lg="12" md="12">
                <Card className="card-block">
                    <Card.Header>
                        <h4 className="">
                            Events Report
                        </h4>
                    </Card.Header>
                    <CommonDateRange setState={setDateRange} showSwitch={true} setType={setType} type={type} />

                    <Card.Body className='px-0'>
                        <div className="table-responsive">
                            <table
                                id="datatable-ecom"
                                ref={listtableRef}
                                className="data-tables table custom-table movie_table"
                            ></table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default EventReports
