import React, { memo, Fragment, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header2 from "../../../modules/landing-pages/components/partials/header/header-2";
import { Button } from "react-bootstrap";
import MainFooter from "../../Event/FooterComps/MainFooter";
import MobileBottomMenu from "./MobileBottomMenu";
import { useMyContext } from "../../../../Context/MyContextProvider";
import { ChevronUp } from "lucide-react";
import StaticMissedCall from "./StaticMissedCall";

const DefaultLayout = memo((props) => {
  const { isMobile } = useMyContext()
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (document.documentElement.scrollTop > 250) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    document.body.classList.add('landing-pages');
    document.documentElement.classList.add('landing-pages');
    document.body.classList.add('body-bg');

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.body.classList.remove('landing-pages');
      document.documentElement.classList.remove('landing-pages');
      document.body.classList.remove('body-bg');
    };
  }, []);
  const location = useLocation();
  const noMarginPaths = ['/', '/home'];
  const shouldApplyMargin = !noMarginPaths.includes(location.pathname) && !isMobile;
  return (
    <Fragment >
      {/* <HomeArtistSection isMobile={isMobile}/> */}
      <main className="main-content" style={{ marginTop: shouldApplyMargin ? '8rem' : '0rem' }}>
        <div className="position-fixed w-100 top-0" style={{ zIndex: '9999' }}>
          {!isMobile && <Header2 />}
        </div>
        <div>
          <Outlet />
        </div>
      </main>
      <MainFooter />
      {isMobile && <MobileBottomMenu />}
      <StaticMissedCall />
      {showBackToTop && (
        <div id="back-to-top">
          <Button size="xs" variant="secondary p-0 position-fixed top" href="#top" style={{ bottom: '6rem' }}>
            <ChevronUp size={34} />
          </Button>
        </div>
      )}
      {/* {{!-- Back-To-end --}} */}
    </Fragment>
  );
});

DefaultLayout.displayName = "DefaultLayout";
export default DefaultLayout;
