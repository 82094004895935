import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Image, Modal, Row } from 'react-bootstrap'
import BookingsAttendee from '../Events/LandingEvents/OrderComps/BookingsAttendee';

const ScanedUserData = (props) => {
    const { show, ticketData, type, setShow, showAttendeee, attendees, categoryData, handleVerify, event } = props

    const [hasData, setHasData] = useState(false);
    const [section, setSection] = useState(parseInt(event?.scan_detail));
    useEffect(() => {
        if (event) {
            console.log(parseInt(event?.scan_detail))
            setSection(parseInt(event?.scan_detail))
        }
    }, [event])
    const fields = [
        // ...(hasData &&
        //     (ticketData?.attendee?.Photo || ticketData?.bookings?.[0]?.attendee?.Photo)
        //     ? [
        //         {
        //             // label: "Photo",
        //             isPhoto: true,
        //             value:
        //                 ticketData?.attendee?.Photo ||
        //                 ticketData?.bookings?.[0]?.attendee?.Photo,
        //         },
        //     ]
        //     : []
        // ),
        {
            label: "Mo",
            value: hasData
                ? ticketData?.number || ticketData?.bookings?.[0]?.number || ""
                : "",
        },
        {
            label: "Name",
            value: hasData
                ? ticketData?.name || ticketData?.bookings?.[0]?.name || ""
                : "",
        },
        {
            label: "Ticket",
            value: hasData
                ? ticketData?.ticket?.name || ticketData?.bookings?.[0]?.ticket?.name || ""
                : "",
        },
        {
            label: "QTY",
            value: hasData
                ? ticketData?.quantity || ticketData?.bookings?.length || 1
                : "",
        },
        {
            label: "From",
            value: hasData
                ? ticketData?.ticket?.event?.date_range?.split(",")[0] ||
                ticketData?.bookings?.[0]?.ticket?.event?.date_range?.split(",")[0] ||
                ""
                : "",
        },
        {
            label: "To",
            value: hasData
                ? ticketData?.ticket?.event?.date_range?.split(",")[1] ||
                ticketData?.bookings?.[0]?.ticket?.event?.date_range?.split(",")[1] ||
                (ticketData?.ticket?.event?.date_range?.split(",")?.length === 1
                    ? ticketData?.ticket?.event?.date_range
                    : "") ||
                (ticketData?.bookings?.[0]?.ticket?.event?.date_range?.split(",")?.length === 1
                    ? ticketData?.bookings?.[0]?.ticket?.event?.date_range
                    : "") ||
                ""
                : "",
        },
    ];
   

    useEffect(() => {
        setHasData((Object.entries(ticketData)?.length || ticketData?.bookings?.length) > 0);
    }, [ticketData]);
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        // centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {hasData && (ticketData?.ticket?.event?.name || ticketData?.bookings?.[0]?.ticket?.event?.name || '')} - {type}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="">
                    {section !== 0 && showAttendeee && (
                        <BookingsAttendee
                            Slider={true}
                            attendeeList={attendees}
                            apiData={categoryData?.customFieldsData}
                            ShowAction={false}
                        />
                    )}
                    {section !== 1 &&
                        <Card className="shadow-none mb-0">
                            <Card.Body>
                                <Row>
                                    {fields?.map((field, index) => (
                                        <Col
                                            key={index}
                                            xs={field?.isPhoto ? 12 : 6}
                                            md={field?.isPhoto ? 12 : 4}
                                            className={`d-flex align-items-center gap-3 mb-3 ${field?.isPhoto && 'justify-content-center'}`}
                                        >
                                            {field?.isPhoto ? (
                                                <Image
                                                    src={field?.value}
                                                    alt="Field Image"
                                                    style={{ width: "150px", height: "150px", objectFit: "cover" }}
                                                />
                                            ) : (
                                                <>
                                                    <p className="p-0 m-0">{field?.label}:</p>
                                                    <h6 className="p-0 m-0">{field?.value}</h6>
                                                </>
                                            )}
                                        </Col>
                                    ))}
                                </Row>
                            </Card.Body>
                        </Card>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button onClick={()=>handleVerify()}>Verify</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ScanedUserData
