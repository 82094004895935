import React, { useCallback, useEffect, useMemo, useState } from 'react'
import CountCard from '../../Dashboard/CountCard'
import { Col } from 'react-bootstrap'
import axios from 'axios';
import { useMyContext } from '../../../../../Context/MyContextProvider';

const BookingCount = ({type,date }) => {
    const {api,authToken} = useMyContext()
    const [counts, setCounts] = useState({
        totalDiscount: 0,
        totalAmount: 0,
        totalQuantity: 0,
    });
    useEffect(() => {
        if (type) {
            calculateTotals(type)
        }
    }, [type,date]);

    const calculateTotals = useCallback(async () => {
        if (!type) return; // Prevent execution if type is not provided
        try {
            const url = `${api}getDashboardSummary/${type.toLowerCase()}?date=${date}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            const data = response.data;
            if (data) {
                setCounts({
                    totalDiscount: data.totalDiscount,
                    totalAmount: data.totalAmount,
                    totalBookings: data.totalBookings,
                    totalTickets: data.totalTickets,
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [api, authToken, type, date]);

    const countCardData = useMemo(() => [
        { title: "Amount", amount: counts.totalAmount },
        { title: "Discount", amount: counts.totalDiscount },
        { title: "Bookings", amount: counts.totalBookings, hideCurrency: true },
        { title: "Tickets", amount: counts.totalTickets, hideCurrency: true },
    ], [counts]);
    
    return (
        <>
            {countCardData.map((data, index) => (
                <Col key={index} sm="2">
                    <CountCard
                        title={data.title}
                        amount={data.amount}
                        hideCurrency={data.hideCurrency}
                    />
                </Col>
            ))}
        </>
    )
}

export default BookingCount
