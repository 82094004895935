import React, { useState } from 'react';
import { Col, Image, Row, Tab, Table } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useMyContext } from '../../../../../../Context/MyContextProvider';
import FsLightbox from "fslightbox-react";
const Media = ({ youtubeUrl, isMobile, images }) => {
    let img = [];
    try {
        img = JSON.parse(images.replace(/\\/g, ""));
    } catch (error) {
        //console.error("Invalid image JSON string", error);
    }
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1,
    });

    const openLightbox = (index) => {
        setLightboxController({
            toggler: !lightboxController.toggler, // Toggle lightbox
            slide: index + 1, // FS Lightbox slides are 1-based
        });
    };
    const [metaData, setMetaData] = useState({
        title: "Default Title",
        description: "Default Description",
        keywords: "default, keywords",
        ogImage: "", // Open Graph Image for social sharing
    });
    return (
        <>
            <Row>
                <Col xs={12}>
                    <ReactPlayer
                        url={youtubeUrl}
                        controls
                        width={`${isMobile ? '350px' : '800px'}`}
                        height={`${isMobile ? '250px' : '400px'}`}
                    />
                </Col>
                <Col>
                    <Row className="g-3 mt-2">
                        {img?.map((path, i) => (
                            <Col xs={6} sm={6} md={3} lg={3} key={i}>
                                <Image
                                    src={path}
                                    alt="event-details"
                                    className="img-fluid iq-product-img rounded-3 w-100"
                                    loading="lazy"
                                    // onClick={() => openLightbox(i)}
                                    style={{ cursor: "pointer" }}
                                />
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
            <div style={{ position: "relative", zIndex: lightboxController.toggler ? 1000 : 'auto' }}>
                <FsLightbox
                    toggler={lightboxController.toggler}
                    sources={img} // Pass all images to lightbox
                    slide={lightboxController.slide}
                />
            </div>
        </>
    );
}

const Info = ({ ticketTerms }) => (
    <Table bordered className="mb-0" responsive>
        <tbody>
            <tr>
                <td className="mb-0" dangerouslySetInnerHTML={{ __html: ticketTerms?.replace(/\r\n/g, '<br />') }} />
            </tr>
        </tbody>
    </Table>
);
const Description = ({ description }) => (
    <div className="d-flex flex-column">
        <p className="mb-0 text-black" dangerouslySetInnerHTML={{ __html: description }}></p>
    </div>
);
const MapCode = ({ location }) => {
    return (
        <div className="d-flex flex-column">
            <iframe
                className="w-100"
                title="map"
                src={location}
                height="500"
                allowFullScreen=""
                loading="lazy"
            ></iframe>
        </div>
    )
};
const EventLayout = ({ src }) => {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <Image
                width={400}
                src={src}
                alt="event-layout"
            />
        </div>
    )
};
const TabContents = ({ event }) => {
    const { isMobile } = useMyContext()
    const tabContents = [
        { key: "description", eventKey: "first", target: "nav-description", content: <Description description={event?.description} /> },
        { key: "info", eventKey: "second", target: "nav-info", content: <Info ticketTerms={event?.ticket_terms} /> },
        { key: "media", eventKey: "third", target: "nav-review", content: <Media images={event?.images} youtubeUrl={event?.youtube_url} isMobile={isMobile} /> },
        { key: "location", eventKey: "fifth", target: "nav-review", content: <MapCode location={event?.map_code} /> },
        { key: "layout", eventKey: "fourth", target: "nav-layout", content: <EventLayout src={event?.layout_image} /> },
    ];

    return (
        <>
            {tabContents?.map(({ key, eventKey, target, content }) => (
                <Tab.Pane
                    key={key}
                    eventKey={eventKey}
                    id={target}
                    role="tabpanel"
                    aria-labelledby={`nav-${key}-tab`}
                >
                    <div className="d-flex flex-column">
                        {content}
                    </div>
                </Tab.Pane>
            ))}
        </>
    );
};

export default TabContents;
