import React, { useEffect } from "react";
//scss
import "./assets/modules/landing-pages/scss/landing-pages.scss";
import "shepherd.js/dist/css/shepherd.css";
import "flatpickr/dist/flatpickr.css";
import "choices.js/public/assets/styles/choices.min.css";
import "./assets/scss/hope-ui.scss";
import "./assets/scss/pro.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/customizer.scss";
import "./assets/custom/scss/custom.scss";
// Redux Selector / Action
import { useDispatch } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
// import state selectors
import { setSetting } from "./store/setting/actions";
import { MyContextProvider } from "./Context/MyContextProvider";

function App({ children }) {
  const dispatch = useDispatch();
  dispatch(setSetting());
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);


  return (
    <HelmetProvider>
      <div className="App">
        <MyContextProvider>
          {children}
        </MyContextProvider>
      </div>
    </HelmetProvider>
  )
}

export default App;