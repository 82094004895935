import React, { memo, useState, useEffect, useRef } from 'react';
import { Row, Col, Card, Form, } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import useSound from 'use-sound';
import QrScanner from 'qr-scanner';

import beepSound from '../../../../assets/event/stock/tik.mp3';
import errorSound from '../../../../assets/event/stock/error.mp3';
import { useMyContext } from '../../../../Context/MyContextProvider';
import MobileScan from './MobileScanButton';
import ScanedUserData from './ScanedUserData';

const TicketVerification = memo(({
    scanMode = 'manual',
}) => {
    const { api, formatDateTime, authToken, UserData, fetchCategoryData, isMobile } = useMyContext();
    const [QRdata, setQRData] = useState('');
    const [type, setType] = useState('');
    const [show, setShow] = useState(false);
    const [ticketData, setTicketData] = useState([]);
    const [event, setEvent] = useState();

    const [autoCheck, setAutoCheck] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showAttendeee, setShowAttendee] = useState(false);
    const [attendees, setAttendees] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [tokenLength, setTokenLength] = useState(8);
    const [play] = useSound(beepSound);
    const [error] = useSound(errorSound);
    const videoElementRef = useRef(null);

    const getTokenLength = async () => {
        try {
            const res = await axios.get(`${api}scanner-token-length/${UserData?.id}`, {
                headers: { 'Authorization': 'Bearer ' + authToken }
            });

            console.log(res.data.tokenLength)
            if (res.data.status) {
                setTokenLength(res.data.tokenLength);
            }
        } catch (err) {
            // SweetalertError(err.response.data.message);
        } finally {
            // setIsProcessing(false);
        }
    };

    useEffect(() => {
        getTokenLength();
    }, []);

    // Get ticket details when QR data is complete
    const getTicketDetail = async (data) => {
        try {
            const res = await axios.post(`${api}verify-ticket/${data}`,
                { user_id: UserData?.reporting_user },
                { headers: { 'Authorization': 'Bearer ' + authToken } }
            );
            if (res.data.status) {
                play();
                const mainBookings = res?.data?.bookings
                setTicketData(mainBookings);
                setEvent(res?.data?.event)
                setType(res?.data?.type);
                const event = res.data?.event
                getCategoryData(event)
                setShowAttendee(res.data?.attendee_required === 1);
                if (res.data?.is_master) {
                    const combinedAttendees = mainBookings?.bookings
                        ?.map(booking => { return booking?.attendee })
                        ?.flat();

                    setAttendees(combinedAttendees)
                } else if (mainBookings?.attendee) {
                    setAttendees([mainBookings?.attendee])
                }
                setShow(true);
            }
        } catch (err) {
            setQRData('');
            const formattedTime = formatDateTime(err?.response?.data?.time);
            const message = err?.response?.data?.time
                ? `Check In: <strong>${formattedTime}</strong>`
                : '';

            Swal.fire({
                icon: 'error',
                title: err?.response?.data?.message,
                html: message,
                timer: 1000
            });
            error();
        }
    };


    const getCategoryData = async (event) => {
        let data = await fetchCategoryData(event?.category?.id)
        setCategoryData(data)
    }


    // Trigger ticket detail fetch when QR data reaches correct length
    useEffect(() => {
        console.log(tokenLength)
        if (QRdata?.length === tokenLength) {
            getTicketDetail(QRdata);
        }
    }, [QRdata]);


    // Verify ticket
    const handleVerify = async () => {
        if (QRdata && !isProcessing) {
            setIsProcessing(true);
            console.log(QRdata)
            try {
                const res = await axios.get(`${api}chek-in/${QRdata}`, {
                    headers: { 'Authorization': 'Bearer ' + authToken }
                });

                if (res.data.status) {
                    Sweetalert();
                    setQRData('');
                    setShow(false);
                }
            } catch (err) {
                SweetalertError(err.response.data.message);
            } finally {
                setIsProcessing(false);
            }
        }
    };

    // Success alert
    const Sweetalert = () => {
        Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Ticket Scanned Successfully.",
            timer: 1000,
            willClose: () => setIsProcessing(false)
        });
    };

    // Error alert
    const SweetalertError = (data) => {
        Swal.fire({
            icon: "error",
            title: data,
            timer: 1000,
            willClose: () => setIsProcessing(false)
        });
    };

    // Auto-check mechanism
    useEffect(() => {
        if (show && autoCheck) {
            const timer = setTimeout(handleVerify, 1500);
            return () => clearTimeout(timer);
        }
    }, [show, autoCheck]);

    // Camera QR scanning setup
    useEffect(() => {
        if (scanMode === 'camera' && videoElementRef.current) {
            const qrScanner = new QrScanner(
                videoElementRef.current,
                (result) => {
                    if (result?.data) {
                        setQRData(result.data);
                    }
                },
                {
                    returnDetailedScanResult: true,
                    highlightScanRegion: true,
                    highlightCodeOutline: true,
                }
            );
            qrScanner.start();

            return () => {
                qrScanner.stop();
                qrScanner.destroy();
            };
        }
    }, [scanMode]);




    // Camera styles (if applicable)
    const styles = scanMode === 'camera' ? {
        qrVideo: {
            objectFit: 'cover',
            // width: '310px',
            // height: '500px',
            height: '70vh',
            borderRadius: '10px',
        }
    } : {};



    return (
        <>
            {/* Ticket Details Modal */}
            <ScanedUserData
                show={show}
                event={event}
                ticketData={ticketData}
                type={type}
                setShow={setShow}
                showAttendeee={showAttendeee}
                attendees={attendees}
                categoryData={categoryData}
                handleVerify={handleVerify}
            />
            {/* Event Statistics */}
            <Row>
                {isMobile && <MobileScan />}
                {/* Scanner Input */}
                <Col sm="12" lg="3">
                    <Card>
                        <Card.Body className="d-flex justify-content-center flex-column">
                            <div className='d-flex justify-content-end'>
                                <div className='d-flex gap-2'>
                                    <Form.Check.Label htmlFor="flexSwitchCheckDefault">
                                        Auto Check
                                    </Form.Check.Label>
                                    <Form.Check className="form-switch">
                                        <Form.Check.Input
                                            type="checkbox"
                                            className="me-2"
                                            id="flexSwitchCheckDefault"
                                            onChange={(e) => setAutoCheck(e.target.checked)}
                                        />
                                    </Form.Check>
                                </div>
                            </div>

                            {/* Conditional rendering based on scan mode */}
                            {scanMode === 'manual' ? (
                                <Form.Control
                                    type="text"
                                    value={QRdata}
                                    onChange={(e) => setQRData(e.target.value)}
                                    placeholder="QR Data"
                                    maxLength={9}
                                    autoFocus
                                />
                            ) : (
                                <video style={styles.qrVideo} ref={videoElementRef} />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
});

export default TicketVerification;