import React, { Fragment } from 'react'
import MobBookingButton from './BookingUtils/MobBookingButton'
import { useMyContext } from '../../../../Context/MyContextProvider'
import CommonHeader from './CommonHeader';
import { Button, Card, Col, Row } from 'react-bootstrap';
import CommonDateRange from '../CustomHooks/CommonDateRange';
import BookingCount from '../Events/Bookings/BookingCount';
import { FileDown } from 'lucide-react';
import TableWithLoader from '../TableComp/TableWithLoader';

const CommonListing = (props) => {
    const { bookings, dateRange, loading, listtableRef, columns, setDateRange, HandleExportAgentReport, bookingLink,tile } = props;
    const { UserPermissions, isMobile } = useMyContext();
    return (
        <Fragment>
            {/* print model  */}

            {isMobile &&
                <MobBookingButton to={bookingLink} />
            }
            {/* print model end */}
            <Row>
                <Col sm="12">
                    <Card>
                        <CommonHeader tile={tile} setDateRange={setDateRange} bookingLink={bookingLink} />
                        <Row className="d-flex align-items-center">
                            {!isMobile &&
                                <Col sm="2">
                                    <CommonDateRange setState={setDateRange} removeClass={true} />
                                </Col>
                            }
                            {/* {UserPermissions.includes('Total Summary POS') &&
                            } */}
                            <BookingCount data={bookings} date={dateRange} type={tile} />
                            {/* {UserPermissions.includes('Export POS Bookings') &&
                            } */}
                                <Col sm="2">
                                    <Button className="btn-secondary" onClick={HandleExportAgentReport}>
                                        <FileDown />
                                    </Button>
                                </Col>
                        </Row>
                        <Card.Body className="px-0">
                            <TableWithLoader
                                ref={listtableRef}
                                loading={loading}
                                columns={columns.current}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default CommonListing
