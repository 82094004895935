import { ChevronLeft } from 'lucide-react';
import React from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../../../../../Context/MyContextProvider';

const CommonMobileTopBar = ({ isMobile }) => {
    const {isScrolled} = useMyContext()
    const navigate = useNavigate()

    if (!isMobile) return null; // Ensure the component only renders for mobile

    return (
        <div
            className="w-100 py-2"
            style={{
                position: 'fixed',
                left: '0',
                zIndex: '99',
                top: '0',
                backdropFilter: 'blur(5px)',
                boxShadow: isScrolled ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none', // Apply shadow when scrolled
                transition: 'box-shadow 0.3s ease', // Smooth transition for the shadow
            }}
        >
            <Row>
                <Col xs={2} className="p-0">
                    <div className="d-flex justify-content-center align-items-center">
                        <Button style={{ background: 'none' }} onClick={() => navigate(-1)} className='p-0 m-0 border-0'>
                            <ChevronLeft color='grey' size={30} />
                        </Button>
                    </div>
                </Col>
                <Col xs={10} className="p-0">
                </Col>
            </Row>
        </div>
    );
};
export default CommonMobileTopBar
