import React, { useState, memo, useEffect } from "react";
import { Row, Col, Image, Nav, Tab, Form, Button, Table, Card, Container, Modal, Alert } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ShareOffcanvas from "../../../../../components/partials/components/shareOffcanvasNew";
import partyImage from "../../../../../assets/modules/e-commerce/images/product/party3.jpg";
import axios from "axios";
import { useMyContext } from "../../../../../Context/MyContextProvider";
import { useDispatch } from "react-redux";
import { logout, signIn } from "../../../../../store/slices/authSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import CommonPricingComp from "../../TicketModal/CommonPricingComp";
import MobileBookNowButton from "./MobileBookNowButton";
import CommonMobileTopBar from "../../CustomUtils/BookingUtils/CommonMobileTopBar";
import DetailsTab from "./EventDetailTabComps/DetailsTab";
import TabContents from "./EventDetailTabComps/EventTabContents";
import MetaData from "../../CustomUtils/MetaData";
import { MapPin, ShoppingCart } from "lucide-react";
import EventDatesModal from "./OrderComps/EventDatesModal";
const EventDetail = memo(() => {
    const { api, UserData, isMobile, authToken, successAlert, formatDateRange, convertTo12HourFormat, systemSetting } = useMyContext();

    const { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [event, setEvent] = useState([]);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [credential, setCredential] = useState('')

    const [modelShow, setModelShow] = useState(false);
    const [signUp, setSignUp] = useState(true);
    // otp state 
    const [otpState, setOtpState] = useState(false);
    const [otp, setOTP] = useState('');
    const [attempts, setAttempts] = useState(0);
    const [timerVisible, setTimerVisible] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        scrollToTop()
        axios.get(`${api}event-detail/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
                setEvent(res.data.events)
            }
        }).catch((err) =>
            console.log(err)
        )
    }, []);


    const handleBooking = async () => {
        if (Object?.keys(UserData).length > 0) {
            if(event?.category?.title === 'Amusement'){
                if(selectedDate){
                    navigate('/events/' + id + '/process', { state: { selectedDate } });
                } else{
                    handleShowDateModal()
                }
            }else {
                navigate('/events/' + id + '/process');
            }
        } else {
            setModelShow(true)
        }
    }
    const handleSignUp = async () => {
        if (!name || !email || !number) {
            setError('Please fill in all fields.');
            return;
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }
        await axios.post(`${api}create-user`, {
            'name': name,
            'email': email,
            'number': number,
            'password': number,
            'role_id': 4
        }, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
                setOtpState(true)
                setSignUp(false)
                setCredential(number)
                handleLogin(number)
            }
        }).catch((err) => {
            setError(err?.response?.data?.error)
        }
        )
    }


    useEffect(() => {
        let timer;
        if (timerVisible && countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [timerVisible, countdown]);

    useEffect(() => {
        if (countdown === 0) {
            setTimerVisible(false);
        }
    }, [countdown]);

    const handleVerifyOtp = async () => {
        if (otp) {
            const data = { otp, number: credential }
            const user = await dispatch(signIn(data))
            if (user?.type === 'login/fulfilled') {
                successAlert('Success', 'Login Successfully')
                navigate('/events/' + id + '/process');
            } else {
                setError(user?.payload)
            }
        } else {
            setAttempts(prevAttempts => prevAttempts + 1);
            if (attempts >= 2) {
                dispatch(logout())
            }
        }
    };

    const handleBack = async () => {
        setOtpState(false)
        setSignUp(false)
    }

    const [error, setError] = useState('');
    const [loading, setLoading] = useState('');
    const handleLogin = async (data) => {
        setLoading(true)
        let crd = credential || data
        if (!crd) {
            setLoading(false)
            setError('Please Enter The Mobile No / Email Address')
        }
        else {
            try {
                const response = await axios.post(`${api}verify-user`, { data: crd })
                if (response.data.status) {
                    setError(false)
                    setLoading(false)
                    setTimerVisible(true)
                    setCountdown(30)
                    setOtpState(true)
                    setOtpSent(true)
                    setOTP('')
                }
            } catch (err) {
                setLoading(false)
                setError(err.response.data.error)
            }
        }
    }
    const handleClose = () => {
        setModelShow(false)
        setError('')
        setOTP('')
        setEmail('')
        setEmail('')
        setNumber('')
        setLoading(false)
    }

    useEffect(() => {
        setError('')
    }, [otpState, signUp, otpState]);

    const disable = event?.booking_not_start || event?.booking_close
    const formatPhoneNumber = (number) => {
        let cleanNumber = number?.replace(/\D/g, '');
    
        if (cleanNumber?.length > 10) {
            return `+${cleanNumber?.slice(0, -10)}-${cleanNumber?.slice(-10, -6)}-${cleanNumber?.slice(-6, -4)}-${cleanNumber?.slice(-4)}`;
        } else {
            return `${cleanNumber?.slice(0, 4)}-${cleanNumber?.slice(4, 6)}-${cleanNumber?.slice(6)}`;
        }
    };
    const formattedNumber = formatPhoneNumber(systemSetting?.missed_call_no);

    // make state for showDateModal
    const [showDateModal, setShowDateModal] = useState(false);
    const handleShowDateModal = () => setShowDateModal(true);
    const handleCloseDateModal = () => setShowDateModal(false);
    const [selectedDate, setSelectedDate] = useState(null);
    return (
        <Container>
            <MetaData event={event} />
            {/* //login model */}
            <Modal show={modelShow}
                backdrop="static" centered onHide={handleClose}>
                <Modal.Header closeButton>
                    {otpState ? (
                        <h5 className="text-center m-0">OTP Verification</h5>
                    ) : signUp && !otpState ? (
                        <h5 className="text-center m-0">Sign Up</h5>
                    ) : (
                        <h5 className="text-center m-0">Sign In</h5>
                    )}
                </Modal.Header>
                <Modal.Body>
                    {
                        otpState ?
                            <div className="p-3">
                                <div className="form-group">
                                    {error &&
                                        <Alert variant="danger d-flex align-items-center" role="alert">
                                            <svg className="me-2" id="exclamation-triangle-fill" fill="currentColor" width="20" viewBox="0 0 16 16">
                                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                            </svg>
                                            <div>
                                                {error}
                                            </div>
                                        </Alert>
                                    }
                                    <input
                                        type="number"
                                        value={otp}
                                        className="form-control mb-0"
                                        placeholder="Plase Enter Your OTP"
                                        onChange={(e) => setOTP(e.target.value)}
                                    />
                                </div>
                                <div className="d-flex gap-3 justify-content-center">
                                    <div className="text-center pb-3">
                                        <Button type="button" variant="secondary"
                                            onClick={() => handleVerifyOtp()}
                                        >Submit</Button>
                                    </div>
                                    <div className="text-center pb-3">
                                        <Button type="button" variant="primary"
                                            onClick={handleBack}
                                        >Change Number</Button>
                                    </div>

                                </div>
                                <div className="text-center pb-3">
                                    <p className="my-3 fs-6">OTP sent on your Mobile No and Email</p>
                                    {
                                        timerVisible && otpSent ? (
                                            <div>
                                                <p>Resend OTP in {countdown} seconds</p>
                                            </div>
                                        )
                                            :
                                            <Button onClick={() => handleLogin()}>Resend OTP</Button>
                                    }
                                </div>
                            </div>

                            :
                            (signUp && !otpState) ?
                                <>
                                    <div className="p-3">
                                        {error &&
                                            <Alert variant="danger d-flex align-items-center" role="alert">
                                                <svg className="me-2" id="exclamation-triangle-fill" fill="currentColor" width="20" viewBox="0 0 16 16">
                                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                                </svg>
                                                <div>
                                                    {error}
                                                </div>
                                            </Alert>
                                        }
                                        <Row className="d-flex justify-content-between">
                                            <Col sm="12" md="6" className="form-group">
                                                <Form.Label className="form-label" htmlFor="firstName" >Name</Form.Label>
                                                <input type="text" className="form-control mb-0" id="firstName" placeholder="Enter Name" onChange={(e) => setName(e.target.value)} />
                                            </Col>
                                            <div className="form-group col-sm-12 col-md-6">
                                                <Form.Label className="form-label" htmlFor="Phone_NO" >Phone No.</Form.Label>
                                                <input type="number" className="form-control mb-0" id="Phone_NO" placeholder="Enter Phone Number" onChange={(e) => setNumber(e.target.value)} />
                                            </div>
                                        </Row>
                                        <Row className="d-flex justify-content-between">
                                            <Col sm="12" md="12" className="form-group">
                                                <Form.Label className="form-label" htmlFor="Emailid" >Email</Form.Label>
                                                <input
                                                    type="email"
                                                    className="form-control mb-0"
                                                    id="Emailid"
                                                    placeholder="Enter Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                                />
                                            </Col>
                                        </Row>
                                        <div className="text-center pb-3">
                                            <input type="checkbox" className="form-check-input" id="aggrement-hopeui" />
                                            <Form.Label className="form-check-label ms-1" htmlFor="aggrement-hopeui">I agree with the terms of use</Form.Label>
                                        </div>
                                        <div className="text-center pb-3">
                                            <Button type="button" className="btn btn-secondary"
                                                onClick={handleSignUp}
                                            >Sign Up</Button>
                                        </div>
                                    </div>
                                    <p className="text-center">Already have an Account<Link to="#" className="ms-2" onClick={() => setSignUp(!signUp)}>Sign in</Link></p>
                                </>
                                : (!signUp && !otpState) &&

                                <>
                                    <div className="p-3">
                                        <div className="form-group">
                                            {error &&
                                                <Alert variant="danger d-flex align-items-center" role="alert">
                                                    <svg className="me-2" id="exclamation-triangle-fill" fill="currentColor" width="20" viewBox="0 0 16 16">
                                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                                    </svg>
                                                    <div>
                                                        {error}
                                                    </div>
                                                </Alert>
                                            }
                                            <input type="email" className="form-control mb-0" id="email-id" placeholder="Enter email or mobile number" onChange={(e) => setCredential(e.target.value)} />
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <Form.Check className="form-check d-inline-block pt-1 mb-0">
                                                <input type="checkbox" className="form-check-input" id="customCheck11" />
                                                <Form.Label className="form-check-label" htmlFor="customCheck11">Remember Me</Form.Label>
                                            </Form.Check>
                                            <Link to="/auth/reset-password">Forgot password</Link>
                                        </div>
                                        <div className="text-center pb-3">
                                            <Button type="button" className="secondary" disabled={loading} onClick={() => handleLogin()}>{loading ? 'Please Wait...' : 'Sign in'}</Button>
                                        </div>
                                    </div>
                                    <p className="text-center">Don't have account?<Link to="#" className="ms-2" onClick={() => setSignUp(!signUp)}> Click here to sign up.</Link></p>
                                </>
                    }
                </Modal.Body>
            </Modal>
            {/* //login model end*/}
            <Row>
                <Col lg={12}>
                    <CommonMobileTopBar isMobile={isMobile} />
                    <MobileBookNowButton
                        isMobile={isMobile}
                        disable={disable}
                        handleBooking={handleBooking}
                    />
                    <EventDatesModal
                        show={showDateModal}
                        setSelectedDate={setSelectedDate}
                        selectedDate={selectedDate}
                        dateRange={event?.date_range}
                        setShow={setShowDateModal}
                        handleSave={handleBooking}
                    />
                    <hr />
                    <Card className="mt-5">
                        <Card.Body>
                            <Row>
                                <Col lg="12">
                                    <Row className="align-items-center">
                                        <Col lg="5">
                                            <div className="product-vertical-slider">
                                                <div className="d-flex justify-content-center">
                                                    <Image
                                                        width={isMobile ? 250 : 400}
                                                        src={event?.thumbnail || partyImage}
                                                        alt="product-details"
                                                        className="img-fluid iq-product-img hover-media rounded-5"
                                                    />
                                                </div>
                                            </div>
                                            <div className="contacts text-center my-3">
                                                <p>
                                                    <i className="fa-brands fa-whatsapp text-success me-2" />
                                                    Say <strong>Hii</strong> to {formattedNumber}
                                                </p>
                                                <p>
                                                    <FontAwesomeIcon icon={faPhone} className="me-2" />
                                                    Give us a Missed call: {formattedNumber}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg="7" className="mt-4 mt-lg-0">
                                            <div className="border-bottom">
                                                <div className="d-flex flex-column align-content-between justify-items-center mb-3">
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <h4 className="mb-0">{event?.name}</h4>
                                                        <div className="d-flex justify-content-end ">
                                                            <ShareOffcanvas share={true} />
                                                        </div>
                                                    </div>

                                                </div>
                                                {event?.booking_not_start ?
                                                    <p className="text-danger">Booking Not Started Yet</p> :
                                                    event?.booking_close ?
                                                        <p className="text-danger">Booking Closed</p> :
                                                        <div className="d-flex align-items-center">
                                                            <div className="table-responsive  w-100">
                                                                <Table responsive striped className="mb-0" role="grid">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="text-black h5">Ticket Category</th>
                                                                            <th className="text-black h5">Price</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            event?.tickets?.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td className="text-black">{item.name}</td>
                                                                                        <td className="text-black h5">
                                                                                            <CommonPricingComp currency={item.currency} price={item.price} isSale={item?.sale} salePrice={item?.sale_price} /></td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            <div className="d-flex gap-2 border-bottom">
                                                <p className="py-4 mb-0">
                                                    <MapPin />
                                                </p>
                                                <p className="py-4 mb-0">
                                                    {event?.address}
                                                </p>
                                            </div>
                                            <div className="d-flex flex-column py-4">
                                                <div className="d-flex align-items-center mb-3">
                                                    <span className="text-dark">Event Date:</span>
                                                    <span className="text-primary  ms-2">
                                                        {formatDateRange(event?.date_range)}
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center mb-3">
                                                    <span className="text-dark">Starts At:</span>
                                                    <span className="text-primary  ms-2">{convertTo12HourFormat(event?.start_time)}</span>
                                                </div>
                                            </div>
                                            {
                                                !isMobile &&
                                                <div>
                                                    <div className={`d-flex py-4 flex-wrap gap-4 ${disable && 'opacity-50'}`}
                                                        onClick={() => !disable && handleBooking()}>
                                                        <Link
                                                            disabled={disable}
                                                            className="btn btn-warning d-flex align-items-center gap-2"
                                                        >
                                                            <span className="btn-inner d-flex ">
                                                                <ShoppingCart />
                                                            </span>
                                                            Book Now
                                                        </Link>
                                                    </div>
                                                </div>
                                            }

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Tab.Container defaultActiveKey="first">
                                <Nav className="tab-bottom-bordered">
                                    <DetailsTab />
                                </Nav>
                                <Tab.Content className="mt-4 iq-tab-fade-up" id="nav-tabContent">
                                    <TabContents event={event} />
                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
});
EventDetail.displayName = "EventDetail";
export default EventDetail;
