import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { useMyContext } from '../../../../Context/MyContextProvider'
import { Link } from 'react-router-dom'
import CommonDateRange from '../CustomHooks/CommonDateRange'

const CommonHeader = ({ setDateRange, tile, bookingLink }) => {
    const { isMobile } = useMyContext()
    return (
        <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
                <h4 className="card-title">{tile} {!isMobile && 'Bookings'}</h4>
            </div>
            {!isMobile &&
                <div className="button">
                    <h4 className="card-title">
                        {/* <Link to={"new"}> */}
                        <Link to={bookingLink}>
                            <Button className="me-4 hvr-curl-top-right border-0">
                                Book Ticket
                            </Button>
                        </Link>
                    </h4>
                </div>
            }
            {isMobile &&
                <CommonDateRange setState={setDateRange} removeClass={true} />
            }
        </Card.Header>
    )
}

export default CommonHeader
